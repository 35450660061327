import React from 'react';

const SmartContractAdress = () => {
    return (
        <div>
            <div className="my-20 _0shine ">
                <div className="  py-12 my-20 space-y-4 w-[80%] m-auto">
                    <div className="space-y-5">
                        <h1 className="font-semibold text-[22px] xl:text-2xl 2l:text-3xl">
                            Smart Contract Address
                        </h1>
                    </div>
                    <div className="space-y-5">
                        <div className="">
                            <h1 className="font-semibold text-lg xl:text-xl">BIBK:</h1>
                            <a
                                class=""
                                href="https://etherscan.io/address/0x9457e7cd71bebdda47d81248becede74afa22125"
                            >
                                <p className="underline break-words text-[#92D940] cursor-pointer">
                                    0x9457e7cd71bebdda47d81248becede74afa22125
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmartContractAdress;