import React from "react";
import AdminNav from "../Components/AdminNav";

const BibkMonthly = () => {
  const MyBackgroundImage = "/img/bg1.png";
  return (
    <>
      <AdminNav />
      <div className="py-28 lg:py-2 xl:py-28 m-auto w-[90%] lg:h-[75vh] xl:h-min lg:flex flex-col justify-center">
        <div className="_0shine space-y-10 xl:space-y-5 text-justify rounded-xl p-5 xl:p-12 ">
          <div className="xl:flex space-y-4 xl:space-y-0 items-center justify-between ">
            <div className="">
              <h1 className="text-3xl xl:text-4xl font-bold">
                BIBK Monthly
              </h1>
              <p className="text-[#92D940]">
                Claim 3.3 BIBK monthly per BinaryBlock genesis
              </p>
            </div>
            <div className="">
              <p className="text-white">BIBK/ETH: 0.0000</p>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div
              className="xl:w-[400px] h-[380px] w-[300px] md:w-[400px] "
              style={{
                backgroundImage: `url(${MyBackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="flex flex-col h-[400px] justify-center space-y-2">
                <div className=" leading-tight text-center">
                  <h1 className="">
                    {" "}
                    <span className=" text-[#92D940] ">
                      BIBK Earned
                    </span>
                  </h1>
                </div>
                <div className="text-center">
                  <h1 className="text-6xl  no">0.0000</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center py-10 text-center ">
            <button className="bg-[#92D940] hover:bg-[#71a832] rounded-full text-black py-4 px-4 w-[100%] xl:w-[30%]">
              <p className="">
                Claim BIBK
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BibkMonthly;
