import React from 'react';
import AdminNav from '../Components/AdminNav';

const Terms = () => {
    return (
        <div>
            <AdminNav />
            <div className="w-[90%] m-auto">
                <div className="py-10 pb-20 space-y-10">
                    {/* Terms  of use */}
                    <div className="space-y-5">
                        <h1 className="text-center 2xl:text-3xl xl:text-2xl text-xl text-[#92D940] font-bold">Welcome to BinaryBlock.io and the Dapp!</h1>
                        <p className="">These Terms of Use ("Terms") apply to your use of the BinaryBlock.io website, referred to as "BinaryBlock.io," and the App.BinaryBlock.io interface, known as the "Dapp," collectively referred to as the "services." GpChange SA, Inc. provides these services), referred to as "we," "us," or "our.</p>
                    </div>

                    <div className="">
                        <ul className="list-disc ml-10">
                            <li className="">
                                <span className="text-[#92D940]">BinaryBlock.io </span>
                                provides information related to the Protocol and the ecosystem.
                            </li>
                            <li className="">
                                <span className="text-[#92D940]">  The Dapp  </span>
                                (the Interface), an autonomous platform, offers accessible applications for interacting with the BinaryBlock Protocol through self-custodial wallets.".
                            </li>
                        </ul>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-[#92D940]"> (I) ARBITRATION NOTICE:</h1>

                        <h3 className=""> The following terms contain an arbitration clause. Unless mentioned otherwise in that clause, you and we agree that Any disputes concerning the services (as defined below) shall be resolved through mandatory binding arbitration. This means that you waive any right to a trial by jury or to participate in a class-action lawsuit or class-wide arbitration.</h3>
                        <div className="">
                            <ul className="list-decimal space-y-4 ml-10">
                                <li className="">
                                    By accessing or utilizing our services, including our various websites like binaryblock.io and app.binaryblock.io, applications, and other services linked to these terms, along with any information, text, links, graphics, photos, audio, video, or other materials accessible, retrieved, or displayed therein, whether accessed through the site or by other means (collectively referred to as the "Services"), you are entering into a binding agreement with us. This Agreement encompasses these terms, the privacy policy, and any other policies referenced herein (collectively called the "Agreement").
                                </li>
                                <li className="">
                                    Use of the Services: To use the Services, you must legally be able to enter into the Agreement. By using the Services, you represent and warrant that you meet the eligibility requirement. If you do not meet the requirement, you must not access or use the Site or the Services.
                                </li>
                                <li className="">
                                    We may update the Services and the Terms: We may change or update the Services, the Agreement, and any part of the Terms at any time, for any reason, at our sole discretion. Once any part of the Agreement is updated and in effect, you will be bound by the Terms if you continue to use the Services. We may, at any time, and without liability to you, modify or discontinue all or part of the Services (including access to the Services via any third-party links).
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-[#92D940]"> (II) SERVICES:</h1>
                        <ul className="ml-10 list-disc space-y-4">
                            <li className="">    Informational Resource and Disclaimer: Binaryblock.io serves as an informational resource about the Protocol but is not the exclusive or sole source. All information provided in connection with your use of the Services is intended for informational purposes only. While we strive for accuracy and reliability, we cannot guarantee the information's accuracy, completeness, or timeliness.</li>

                            <li className=""> User Control of Cryptoassets: Users maintain full control over their cryptoassets. We, nor any affiliated entity, are party to any transaction on the blockchain networks underlying the Binaryblock Protocol. We do not possess, have custody over, or control any cryptoassets.</li>
                            <li className="">
                                Use of Third-Party Self-Custodial Wallets: To interact with the Binaryblock Protocol using the Interface, you must connect and engage with it through your self-custodial wallet. Your self-custodial wallet is provided by a third-party entity and is governed by separate terms and conditions set by the respective third-party service provider.
                            </li>

                            <li className="">
                                Non-Intermediary Status: Due to the non-custodial and decentralized nature of blockchain technology, we are not intermediaries, agents, advisors, or custodians. We do not have a trustee relationship or obligation to you regarding any decisions or activities you undertake when using our Services.
                            </li>
                            <li className="">
                                Limited Information on Protocol Transactions: We do not have information about all Binaryblock Protocol transactions beyond what is publicly available via the blockchain. However, we may collect information regarding the users of the Services in accordance with our Privacy Policy.
                            </li>
                            <li className="">
                                Blockchain Fees: Transactions on blockchains may require the payment of gas fees, which are network transaction fees. Gas fees are non-refundable. We do not provide services related to user transactions or handle cryptoasset payments. We do not receive fees for any transactions or the Services.
                            </li>
                            <li className="">
                                Decentralized Infrastructure: The Interface is hosted on a decentralized and distributed network, utilizing a peer-to-peer protocol for storing and retrieving files. This results in the Interface's files being dispersed across multiple nodes within the network, rather than being centralized in a single location.
                            </li>

                        </ul>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-[#92D940]">(III) ASSUMPTION OF RISK</h1>
                        <ul className="ml-10 space-y-4 list-disc">
                            <li className="">
                                You accept the risks of new and experimental tech: This includes technologies like smart contracts on the  blockchains, cryptographic tokens, and other emerging software, applications, and systems interacting with blockchain-based networks. These technologies are considered experimental, speculative, and inherently risky, subject to change.
                            </li>
                            <li className="">
                                Third-Party Services and Links Disclaimer: We are not accountable for any third-party services or links. This includes networks or apps like Discord or MetaMask. We do not endorse or guarantee the content or accuracy of any third-party services or materials. The use and access of any third-party products or services, including through our Services, are at your own risk.
                            </li>
                            <li className="">
                                Automated Collection and Disbursement of Proceeds: You understand and agree that all transactions accessed through blockchain-based networks will be automatically processed using one or more smart contracts.
                            </li>
                            <li className="">
                                Risk Acknowledgement for Using the Services: You are solely responsible for evaluating the Services before use. All transactions on the blockchain are irreversible, final, and without refunds. The Services may be disabled, disrupted, or adversely impacted due to factors such as cyber-attacks, surges in activity, computer viruses, and other operational or technical challenges.
                            </li>
                            <li className="">
                                Security of Self-Custodial Wallet: You are solely responsible for the security of your self-custodial wallet and private keys associated with it. Unauthorized access by third parties may lead to the loss or theft of cryptoassets or funds. We do not manage or maintain the security of your self-custodial wallet, and we do not have access to your private keys.
                            </li>
                            <li className="">
                                Access Restriction Rights: We reserve the right to restrict your access to the Services if we suspect, at our sole discretion, that you are engaging in illegal activities, fraud, or inappropriate methods to acquire cryptoassets. This includes being subject to sanctions or listed on specific lists administered by legal or regulatory authorities. If we have reasonable suspicion of illegal use, we reserve the right to take appropriate action.
                            </li>
                            <li className="">
                                Service Quality and Accessibility: You acknowledge that the Services may occasionally be inaccessible or inoperable due to various reasons beyond our control, including equipment malfunctions, maintenance, or repairs. This may also include disruptions or unavailability of underlying blockchain infrastructure or third-party service providers. Accessing and using the Services is at your own risk, and you should not engage in blockchain-based transactions unless suitable given your circumstances and financial resources. You represent that you have conducted your own due diligence regarding the risks of a transaction and the underlying smart contracts and cryptoassets before using the Services.
                            </li>

                        </ul>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-[#92D940]"> (IV) TAXES</h1>
                        <ul className="ml-10 list-disc space-y-4">
                            <li className="">
                                You're in charge of your taxes and duties: Users are solely responsible for handling any taxes, duties, or assessments imposed by governmental authorities related to their use of the Services, as well as any resulting obligations from using cryptoassets and interacting with smart contracts. It's important to note that tax treatment of blockchain-based transactions is still uncertain.
                            </li>
                        </ul>
                    </div>
                    <div className="space-y-4">

                        <h1 className="text-[#92D940]">
                            (V) OWNERSHIP</h1>
                        <ul className="list-disc ml-10 space-y-4">
                            <li className="">
                                We grant you a license: Contingent on your ongoing compliance with the Agreement, we provide you with a personal, worldwide, revocable, non-exclusive, and non-assignable license to use the protocol as part of our Services. This license is solely for the purpose of allowing you to use and enjoy the Services according to these Terms.
                            </li>
                            <li className="">
                                We own all rights: All rights, including copyrights, to any content, code, data, or materials accessible through the Services, belong to us. Your use or access to the Services doesn't grant you any ownership or other rights.
                            </li>
                            <li className="">
                                We may use your feedback: Any feedback you provide about our Services, such as comments, bug reports, or ideas for improvement, is voluntary. We have the freedom to use or not use this feedback as we see fit, including sharing it with third parties, without any obligation to you.
                            </li>
                        </ul>
                    </div>
                    <div className="space-y-2">
                        <h1 className="text-[#92D940]">(VI) PROHIBITED CONTENT</h1>

                        <p className=""> You must comply with this Agreement and applicable laws. The following actions are prohibited:</p>
                        <div className="">
                            <ul className="list-disc ml-10 space-y-2">
                                <li className="">  Engaging in illegal activity, such as money laundering, financing terrorism, tax evasion, or trading illegal goods or weapons</li>
                                <li className="">  Using the Services for unauthorized commercial purposes</li>
                                <li className=""> Uploading malicious code or engaging in any activity that may affect the Services' functionality</li>
                                <li className=""> Unauthorized use, reproduction, or reverse engineering of the Services</li>
                                <li className=""> Unauthorized collection of information from the Services</li>
                                <li className="">  Deceptive or fraudulent behavior</li>
                                <li className="">  Interfering with others' access or use of the Services</li>
                                <li className="">  Interfering with security features or systems</li>
                                <li className="">  Engaging in any form of attack, hack, or exploit</li>
                                <li className="">   Breaking these rules may result in our involvement. If you engage in conduct prohibited by applicable law, we reserve the right to restrict or revoke your access to the Services, either temporarily or permanently, at our discretion. We also reserve the right to edit or alter transaction data to address any damage resulting from a user's violation of this Agreement or applicable law. We may probe breaches and retain the right to investigate and address suspected breaches of this Agreement, including the Terms, and may disclose information as required by law or governmental request.</li>
                            </ul>
                        </div>

                    </div>
                    <div className="space-y-4">
                        <h1 className="text-[#92D940]"> (VII) DISCLIAMERS AND LIMITATIONS OF LIABILITY</h1>
                        <p className="">
                            We do not make any assurances: The Services are provided on an "as is" and "as available" basis. GpChange SA and its affiliated entities, including parents, subsidiaries, officers, directors, employees, agents, representatives, partners, and licensors (collectively, "GpChange SA "), do not provide any guarantees regarding the Services.
                            Limitation of Liability: To the fullest extent permitted by law, no GpChange SA entity shall be liable for any kind of loss, damage, or injury, including direct, indirect, special, incidental, exemplary, consequential, or punitive losses or damages. This includes damages for system failure, loss of profits, data, use, business, goodwill, or other intangible losses arising from:
                        </p>
                        <ul className="list-disc ml-10 space-y-1">

                            <li className="">Use or inability to access the Services</li>
                            <li className="">Misuse or unauthorized access of the Services</li>
                            <li className="">User conduct on the Services</li>
                            <li className="">Termination, suspension, or restriction of access to the Services</li>
                            <li className="">Additionally, no GpChange SA entity shall be liable for damages caused in whole or in part by factors such as user error, server failure, blockchain or network issues, changes in cryptoasset value, legal or policy changes, force majeure events, or actions of third parties.</li>
                            <li className=""> This limitation of liability applies regardless of whether other provisions in these Terms have been breached or proven ineffective. The limitations in this section apply irrespective of the form of action, legal or equitable theory, and whether or not GpChange SA entities have been informed of the possibility of such damage.</li>
                            <li className=""> no event will GpChange SA entities' cumulative liability, from all causes of action and theories of liability, exceed one hundred U.S. dollars (U.S. $100.00).</li>In
                            <li className=""> GpChange SA entities are not obligated to deliver any virtual currency as damages or provide specific performance. If damages are calculated based on the value of virtual currency, it will be based on the lowest value during the period between the accrual of the claim and the award of damages.</li>
                        </ul>
                    </div>
                    <div className="space-y-2">
                        <h1 className="text-[#92D940]"> (VIII) INDEMNIFICATION</h1>
                        <p className=""> You commit to indemnify, defend, and protect GpChange SA from any claim or demand, including reasonable legal fees, arising from:</p>
                        <ul className="list-disc ml-10 space-y-2">
                            <li className="">  Your breach or alleged breach of the Agreement, including these Terms.</li>
                            <li className="">   Contributions you make to the Services.</li>
                            <li className="">   Misuse of the Services, including any associated smart contract or script.</li>
                            <li className="">   Violation of any laws or regulations.</li>
                            <li className="">   Violation of any third-party rights, including intellectual property, publicity, confidentiality, property, or privacy rights.</li>
                            <li className="">  Use of a third-party product, service, or website.</li>
                            <li className="">   Any misrepresentation made by you.</li>
                            <li className="">We retain the right to assume, at your cost, the exclusive defense and control of any matter subject to indemnification by you. You agree to collaborate with our defense of any claim and not to settle any claim without our prior written consent.</li>
                        </ul>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-[#92D940]">  (IX) AGREEMENT FOR ARBITRATION AND FORFEITURE OF RIGHTS, INCLUDING CLASS ACTION</h1>
                        <ul className="list-disc ml-10 space-y-2">
                            <li className="">
                                Before initiating any form of legal action against us, including arbitration as outlined below, both parties commit to attempting to resolve any dispute, claim, or controversy arising from or related to the agreement or the Services (referred to individually as a 'Dispute' and collectively as 'Disputes') through sincere negotiations.</li>
                            <li className="">   Any disputes, conflicts, controversies, or claims related to these Terms of Service and/or your utilization of the Platform, whether arising from your use of the Platform, the Products, or otherwise, will be exclusively governed by and subject to the jurisdiction of the Courts of Panama. This applies irrespective of Panamanian choice of law principles.</li>
                            <li className="">   By declining any alterations to these Terms, you commit to resolving any Dispute between you and us through arbitration, as outlined in this section, effective from the date you initially accepted these Terms or accepted any subsequent modifications to these Terms.</li>
                        </ul>
                    </div>

                    <div className="space-y-4">
                        <h1 className="text-[#92D940]"> (X) FORGOING INJUNCTIONS OR SIMILAR EQUITABLE REMEDIES</h1>

                        <p className="ml-10">To the fullest extent allowed by law, you acknowledge that you will not be entitled to seek an injunction or any other form of equitable relief that might hinder or obstruct the development or utilization of the Services, or any other website, application, content, submission, product, service, or intellectual property owned, licensed, used, or controlled by GpChange SA.</p>

                    </div>
                    <div className="space-y-2">
                        <h1 className="text-[#92D940]"> (XI) TERMINATION; CANCELLATION</h1>

                        <p className="">This Agreement remains in effect until terminated by either party. You can terminate by discontinuing access to the Services. We may terminate if, in our sole judgment, you violate any term or provision of this Agreement, including these Terms. We also reserve the right to restrict or cease providing Services at any time and for any reason, including if we believe:</p>
                        <ul className="ml-10 space-y-1 list-disc">


                            <li className=""> Your usage poses a risk or liability to us;</li>

                            <li className=""> Violation of any laws or regulations.; or</li>

                            <li className=""> It's not commercially feasible to continue offering the Services.</li>
                        </ul>

                        These rights are supplementary to any others we may have. We retain the ability to modify the Services at our discretion, but we're not under an obligation to provide updates. It's your duty to stay informed of any alterations that may impact you. Additionally, we may temporarily withdraw or terminate Services without prior notice.

                    </div>
                    <div className="">
                        <h1 className="text-[#92D940]"> (XII) DIVISIBILITY</h1>

                        <p className="ml-10"> If any provision of the Agreement (including, without limitation, these Terms) is found to be unlawful, void, or unenforceable, it shall still be enforced to the maximum extent allowed by applicable law. The unenforceable portion shall be considered as removed from the Agreement. This determination will not impact the validity and enforceability of the remaining provisions.</p>
                    </div>
                    <div className="">
                        <h1 className="text-[#92D940]">    (XIII) ASSIGNMENT</h1>
                        <p className="ml-10">
                            The Agreement, which includes these Terms, can be assigned to any GpChange SA or its successors in connection with any business related to the Services provided by us, without requiring your prior consent. However, you may not assign or transfer any rights or obligations under the Agreement without obtaining our prior written consent.</p>

                    </div>

                    <div className="">
                        <h1 className="text-[#92D940]">(XIV) ENTIRE AGREEMENT</h1>
                        <p className="ml-10">
                            The Agreement, which encompasses these Terms, the Privacy Policy, and any additional policies or operational guidelines we post on the Services, constitutes the complete agreement and comprehension between you and us, dictating your use of the Services. This supersedes any prior or simultaneous agreements, communications, and proposals, whether spoken or written, between you and us, including earlier versions of these Terms. Our failure to enforce any right or provision in the Agreement, including these Terms, shall not be considered a waiver of such right or provision.
                        </p>
                    </div>

                    <div className="">
                        <h1 className="text-[#92D940]">       (XV) GOVERNING LAW</h1>
                        <p className="ml-10">
                            These Terms of Service and all of the agreements to which these Terms of Service apply are governed by the laws of the Republic of Panama.
                        </p>
                    </div>


                    <div className="">
                        <h1 className="text-[#92D940]">  (XVI) CONTACT US</h1>
                        <p className="ml-10">
                            For inquiries regarding your use of the Services, feel free to reach out to us at <span className="text-[#92D940]"> <a href="mailto:info@binaryblock.io" className="" target=''> info@binaryblock.io </a></span>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;