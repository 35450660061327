import React from 'react';
import EthPoolCard from './EthPoolCard';
import AdminNav from './AdminNav';

const EthereumPrice = () => {
    return (
        <div>
            <AdminNav />
            <div className="xl:grid grid-cols-5 2xl:w-[85%] xl:w-[90%] m-auto space-y-10 xl:space-y-0 gap-20 text-white py-20">

                <div className="space-y-3 col-span-2 xl:text-sm 2xl:text-md">
                    <h1 className="text-[#92D940] text-[24px] font-bold">Ethereum Price Prediction Pools</h1>
                    <div className="space-y-2">
                        <p className="text-sm xl:leading-relaxed">Participate in the Ethereum Price Prediction Pool for a chance to win up to 10 Ethereum weekly. Each entry requires one BinaryBlock Genesis NFT, so the more NFTs you have, the more entries you can make.</p>
                    </div>
                    <div className="space-y-10 2xl:py-2 xl:py-1 py-3">


                        <button className="bg-[#92D940] text-black rounded-xl px-5 py-2" >ETH/USD Pool</button>

                        <div className="">
                            <img src="/img/chart.png" alt="" className="w-full" />
                        </div>
                    </div>
                </div>

                <div className="col-span-3 ">
                    <div className='_0shine p-10 '>
                        <div className="xl:grid grid-cols-3 gap-5 py-12 2xl:py-5 xl:py-2 text-center items-center text-sm">
                            <div className="list-none space-y-5">
                                <li className=""> Total Entries: </li>
                                <li className=""> Total/Current Entries: </li>
                                <li className="">Total Winners:</li>
                                <li className=""> ETH per Winner </li>
                                <li className=""> Initial ETH in pool: </li>
                                <li className=""> Total ETH Distributed: </li>
                                <li className=""> NFTs blocked from pool: </li>
                            </div>
                            <div className="">

                                <div className="list-none text-[#92D940] space-y-5 ">
                                    <li className="">10K </li>
                                    <li className="">7500/900</li>
                                    <li className="">10 </li>
                                    <li className="">1 ETH </li>
                                    <li className="">500 ETH </li>
                                    <li className="">0.00 ETH </li>
                                    <li className="">1-2500 </li>
                                </div>
                            </div>

                            <div className="space-y-2 ">
                                <div className="list-none  space-y-5 ">
                                    <li className="">0.000 </li>
                                    <li className="">0.000</li>
                                    <li className="">0.000</li>
                                    <li className="">mark price:</li>
                                    <li className="">0.000</li>
                                    <li className="">0.000 </li>
                                    <li className="">0.000 </li>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="bg-[#8D8D8D4D] rounded-xl 2xl:p-10 p-8 xl:p-4 text-cente xl:text-[10px] 2xl:text-sm">
                                <div className="xl:grid grid-cols-12 items-center space-y-8 xl:space-y-0">
                                    <div className="space-y-8 col-span-2 2xl:col-span-2 xl:col-span-2">
                                        <h2 className="">Pool Closes</h2>
                                        <div className="">
                                            <img src="/img/no.png" alt="" className="m-auto" />

                                        </div>
                                        {/* <p className="">00:00:00</p> */}
                                    </div>
                                    <div className="">
                                        <div className="bg-[#8D8D8D4D] m-auto  w-[1px] h-[135px]"></div>
                                    </div>
                                    <div className="space-y-8 col-span-2">
                                        <h2 className="">Pool Rewards
                                            Distribution in:</h2>
                                        <div className="">
                                            <img src="/img/no.png" alt="" className="m-auto" />

                                        </div>
                                        {/* <p className="">00:00:00</p> */}
                                    </div>
                                    <div className="">
                                        <div className="bg-[#8D8D8D4D]  m-auto w-[1px] h-[135px]"></div>
                                    </div>
                                    <div className="space-y-8 col-span-4 2xl:col-span-3 xl:col-span-3">
                                        <h2 className="">ETH Closing  Price</h2>
                                        <div className="space-y-2">
                                            <input type="text" className="opacity-[36%] bg-transparent outline-none border-none border-b border " placeholder='Enter ETH Closing Price' />

                                            <button className="bg-[#92d940dd] 2xl:px-4 px-3 py-2 2xl:py-1 xl:py-0 text-[10px] rounded-full"><p className="2xl:text-[10px] xl:text-[8px] xl:py-1 xl:font-medium">Submit To Join Pool</p></button>

                                        </div>

                                    </div>
                                    <div className="">
                                        <div className="bg-[#8D8D8D4D] m-auto  w-[1px] h-[135px]"></div>
                                    </div>
                                    <div className="space-y-8 col-span-1 2xl:col-span-2 xl:col-span-2">
                                        <h2 className="">Pool History</h2>
                                        <div className="leading-tight space-y-2 ">
                                            <p className="opacity-[36%]">Current Pool
                                            </p>
                                            <p className="opacity-[36%]">Previous Pool
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EthereumPrice;