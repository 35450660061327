import React from "react";
import Input from "../Components/Input";
import Navbar from "../Components/Navbar";
import UpcomingEvents from "./UpcomingEvents";
import Footer from "../Components/Footer";
import BibkPredict from "./BibkPredict";
import Hero from "./Hero";
import BinaryBlockProtocol from "./BinaryBlockProtocol";
import SmartContractAdress from "./SmartContractAdress";

const Home = () => {
  function handlebuyBIBK() {
    alert("Coming soon!");
  }
  return (
    <>
      <Navbar />
      <div id="top" className="py-10 m-auto xl:w-[90%] w-[95%]">
        <Hero />
        <BinaryBlockProtocol />
        <BibkPredict />
        <UpcomingEvents />
        <SmartContractAdress />
      </div>
    </>
  );
};

export default Home;