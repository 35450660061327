import React from 'react';

const Hero = () => {
    return (
        <div>
            <div className="card pb:28  xl:pb-20 xl:grid grid-cols-2 gap-8 space-y-10 xl:space-y-0">
                <div className="space-y-5 text-justify rounded-xl p-5 xl:p-12 _0shine">
                    <h1 className="2xl:text-[35px] xl:text-[30px] text-[23px] font-bold text-[#92D940]">
                        BinaryBlock Genesis NFT
                    </h1>

                    <p className="text-sm md:text-base">
                        BinaryBlock genesis NFTs are unique digital assets within the BinaryBlock ecosystem, and they confer valuable benefits to their holders.Here's a summary of the benefits of holding BinaryBlock genesis.
                        <br />
                    </p>

                    <div className="">
                        <ul className="text-sm leading-6 list-disc ml-8">
                            <li className="">Win Weekly Rewards of up to 10 Ethereum with Genesis Pool</li>
                            <li className="">Own a One-of-a-Kind and Highly Valuable Asset</li>
                            <li className=""> Unlock Lifetime Crypto Earning Opportunities</li>
                            <li className=""> Enjoy a Fixed Allocation of BIBK tokens</li>
                            <li className=""> Gain Exclusive Access to Future BinaryBlock Activities </li>
                        </ul>
                    </div>

                    <p className="text-sm md:text-base">
                        Overall, BinaryBlock genesis NFTs offer a combination of uniqueness, value, and ongoing earning potential, making them an intriguing component of the BinaryBlock ecosystem.
                    </p>

                    <div className="space-y-4">
                        <div className="">
                            <h1 className="2xl:text-[30px] xl:text-[25px] md:text-[20px] text-[16px] font-bold text-[#92D940]">
                                BinaryBlock Genesis NFT Allocation
                            </h1>
                        </div>
                        <ul className="text-sm leading-6 ml-8 list-disc">
                            <li className="">2,500 for ecosystem growth and funding</li>
                            <li className="">6,000 for public</li>
                            <li className="">1,500 for team</li>
                        </ul>
                    </div>

                    <button className="bg-[#2622FF]  hover:bg-[#2622ffec] rounded-md p-3 w-[100%]">
                        Mint BinaryBlock Genesis NFT
                    </button>
                    <div className="">
                        <p className="text-center text-xl">10,000/0</p>
                    </div>

                </div>

                {/* Second card */}
                <div className="">
                    <div className="_0shine p-5 lg:p-10 py-10 mb-5 xl:flex gap-8 items-center">
                        <div className="card-content xl:w-[98%]">
                            <p className="text-justify xl:text-start text-sm md:text-base ">
                                The BinaryBlock project features BinaryBlock Genesis NFTs, which are crucial for staking, voting, and other activities. The native token, BIBK, plays a central role in governance and decentralized ecosystem activities such as staking, voting, and prediction pools.
                            </p>
                        </div>
                        <div className="card-img xl:w-[80%]">
                            <img src="/img/pic1.png" alt="" className="m-auto xl:w-full" />
                        </div>
                    </div>

                    {/* Third card */}
                    <div className="xl:flex gap-5">
                        <div className="_0shine border xl:h-[40vh p-5 xl:p-10 py-10 mb-5 space-y-2 xl:w-[55%]">
                            <div className="card-content">
                                <p className="text- text-sm">
                                BinaryBlock is dedicated to establishing a robust ecosystem that not only enables individuals to earn crypto but also ensures the sustainability of these earnings over a lifetime. BinaryBlock aims to sustain a lifetime of crypto earnings.
                                </p>
                            </div>
                            <div className="card-img">
                                <img src="/img/pic2.png" alt="" className="m-auto" />
                            </div>
                        </div>

                        {/* Card 4 */}
                        <div className="_0shine w-[100%]">
                            <div className="card-img">
                                <img
                                    class="img-fluid h-[420px] m-auto pt-8"
                                    src="./img/bibk1111.gif"
                                    alt
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;