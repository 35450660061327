import React from "react";
// import {BsDiscord, BsInstagram, BsLinkedin, BsTelegram, BsTwitter} from "react-icons/bs"
import {
  FaDiscord,
  FaInstagram,
  FaLinkedin,
  FaTelegram,

} from "react-icons/fa";

import { ImLinkedin } from "react-icons/im";
import { BiLogoDiscord } from "react-icons/bi";
import { BsDiscord } from "react-icons/bs";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="bg-[#151515] bottom-0 ">
      <div className="  py-10 2xl:py-6 xl:py-4 w-full z-0 ">
        <div className="xl:space-y-0 space-y-5  m-auto w-[90%] md:w-[90%] md:flex justify-between items-center">
          <a href="#" className="hover:cursor-pointer">
            <div className="col-lg-3 mt-3 xl:mt-0">
              <img
                src="/img/logo3.png"
                alt=""
                className=""
              />
            </div>
          </a>
          <div className="flex items-center gap-2 text-sm">
            <Link to="/privacypolicy">
                <p className="">Privacy</p>
            </Link>
            <div className="">&</div>
            <Link to="/terms">
                <p className="">Terms</p>
            </Link>

            </div>
          <div className="flex items-center md:gap-2 gap-1">
            <a href="https://www.linkedin.com/company/bibkcoin" target="_blank" className="">
              {" "}
              <ImLinkedin className="hover:cursor-pointer  xl:text-3xl   text-xl  rounded-full" />
            </a>

            <a href="https://t.me/+Ob5BC7R0s0tkNjM0" target="_blank" className="">
              {" "}
              <FaTelegram className="hover:cursor-pointer  xl:text-3xl text-xl rounded-full" />
            </a>
            <a href="https://twitter.com/BibkCoin" target="_blank" className="">
              <img src="/img/twitter.svg" alt="" className="2xl:h-[32px] xl:h-[30px] md:h-[20px] h-[20px] " />
            </a>
            <a href="https://discord.gg/fBa9q3hdSj" target="_blank" className="">
              {" "}
              <BsDiscord className="hover:cursor-pointer  xl:text-3xl text-xl rounded-full" />
            </a>
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default Footer;
