import { Fragment, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default function Faqs() {
  var acc = document.getElementsByClassName("accordion");
  var i;
  useEffect(() => {
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }, []);
  return (
    <>
      <Navbar />
      <div className="m-auto w-[80%] py-10 md:w-[75%] xl:w-[70%]">
        <h1 className="text-center text-3xl font-semibold">
          Frequently Asked Questions
        </h1>
        <div className="space-y-">
          <button class="accordion _0shine mt-5 text-center">
            {" "}
            What is the operational principle of the BinaryBlock protocol?
          </button>
          <div class="panel pt-3">
            <p>
              By leveraging smart contracts and automated mechanisms, the BinaryBlock protocol guarantees that holders of BinaryBlock assets receive a daily share of cryptocurrency. This innovative process enables asset holders to accumulate cryptocurrency without active trading or investment.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            What defines a BinaryBlock genesis NFT?
          </button>
          <div class="panel pt-3">
            <p>
              A unique cryptocurrency asset that grants daily earnings for over 20 years to its holder. This valuable asset offers continuous earning opportunities.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            What does BIBK stand for?
          </button>
          <div class="panel pt-3">
            <p>
              BIBK is a utility token in the BinaryBlock ecosystem. It allows interaction with the protocol and grants access to its features.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            What are the fundamental capabilities of the BinaryBlock platform?
          </button>
          <div class="panel pt-3">
            <p>
              The BinaryBlock platform offers several ways for users to earn, including asset staking, prediction activities, trading, lending, and using the BIBK token to engage with the protocol.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            How are the rewards distributed among individual BinaryBlock genesis NFTs?
          </button>
          <div class="panel pt-3">
            <p>
              The NFT's attributes are influenced by its binary digits, impacting its rewards.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            How often rewards are distributed on the BinaryBlock protocol?
          </button>
          <div class="panel pt-3">
            <p>
              Participants who lock in BIBK or BinaryBlock genesis NFTs within the smart contract of the BinaryBlock platform receive daily rewards.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            Is staking on the BinaryBlock protocol open to everyone?
          </button>
          <div class="panel pt-3">
            <p>
              Staking is available to all who hold BinaryBlock assets.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            How do I start staking on BinaryBlock?
          </button>
          <div class="panel pt-3">
            <p>
              Use the staking interface.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            What is a Binary digit?
          </button>
          <div class="panel pt-3">
            <p>
              The smallest unit of information in computing and digital communications is known as a binary digit or bit. It has two possible values, 0 or 1, and is the basis of binary code used in computing systems.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            What sets apart the binary system from the decimal system?
          </button>
          <div class="panel pt-3">
            <p>
              The binary system is a base-2 system that uses only 0s and 1s, while the decimal system is a base-10 system that uses numbers from 0 to 9. Binary has a more significant number of digits for computations and is more space-efficient, whereas decimal is more instinctive for humans and is commonly used in everyday math.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            How does BinaryBlock provide benefits to all its stakeholders?
          </button>
          <div class="panel pt-3">
            <p>
              BinaryBlock aims to establish a sustainable and lucrative platform that benefits all parties involved, encompassing BinaryBlock NFT holders, BIBK holders, and the broader cryptocurrency community.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            How does BinaryBlock revolutionize the method of earning cryptocurrency for individuals?
          </button>
          <div class="panel pt-3">
            <p>
              BinaryBlock transforms the process of earning cryptocurrency, introducing a passive income avenue through asset staking. This innovative approach disrupts conventional norms, delivering a unique earning mechanism that benefits its stakeholders significantly.
            </p>
          </div>

          <button class="accordion _0shine mt-5 text-center">
            {" "}
            How does BinaryBlock revolutionize the method of earning cryptocurrency for individuals?
          </button>
          <div class="panel pt-3">
            <p>
              BinaryBlock introduces a unique earning mechanism that allows users to earn cryptocurrency through asset staking, offering a passive income avenue that benefits its stakeholders significantly.
            </p>
          </div>

        </div>
        <button class="accordion _0shine mt-5 text-center">
          Contacting the BinaryBlock Team?
        </button>
        <div class="panel pt-3">
          <p>
            If you need to contact us, please email us at <span className="text-gray-500 hover:text-gray-600"><a href="mailto:info@binaryblock.io" className="">info@binaryblock.io</a></span>  or connect with us on our social media platforms.
          </p>
        </div>
      </div>
    </>
  );
}
