import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";

const AdminNav = () => {
  const [links, setLinks] = useState([
    {
      link: (
        <Link to="/PredictionPool">
          <p className="" onClick={() => setMenu(false)}>
           BIBK Pool
          </p>
        </Link>
      ),
    },
    {
      link: (
        <Link to="/pricepool">
          <p className="" onClick={() => setMenu(false)}>
           Genesis Pool
          </p>
        </Link>
      ),
    },
    {
      link: (
        <Link to="/app">
          <p className="" onClick={() => setMenu(false)}>
            Genesis Staking
          </p>
        </Link>
      ),
    },
    {
      link: (
        <Link to="/bibkstaking">
          <p className="" onClick={() => setMenu(false)}>
            BIBK Staking
          </p>
        </Link>
      ),
    },
    // {
    //   link: (
    //     <Link to="/bibkmonthly">
    // <p className="" onClick={() => setMenu(false)}>
    // BIBK Monthly
    // </p>
    //     </Link>
    //   ),
    // },
  ]);

  const [menu, setMenu] = useState(false);

  function handleOpen() {
    setMenu(!menu);
  }
  const elementRef = useRef(null);

  useEffect(() => {
    gsap.from(".ease", {
      opacity: 0,
      y: -20,
      duration: 0.5,
      ease: "power2.in",
    });
  }, [handleOpen]);

  return (
    <div>
      <div className={!menu ? "sticky top-0 bg-black w-full py-5 z-10 " : "fixed top-0 bg-black w-full py-3 z-10"}>
        <div className="flex items-center justify-between w-[90%] m-auto ">
          <div className="logo md:flex gap-20 items-center">
            <Link to="/" className="hover:cursor-pointer">
              <img src="/img/logo3.png" alt="" className="" />
            </Link>
          </div>
          <div className="icon flex items-center gap-10">
            <div className="hidden xl:flex gap-8">
              {links.map((link) => (
                <div className="xl:text-[12px] 2xl:text-sm">
                  <p className="">{link.link}</p>
                </div>
              ))}
            </div>
            <button className="bg-[#92D940] rounded border border-[#fff] px-3 py-2 xl:hidden">
              <AiOutlineMenu
                onClick={handleOpen}
                className="text-2xl rounded text-black"
              />
            </button>
            <button className="bg-[#92D940] hover:bg-[#71a832]  hover:cursor-pointer py-2 px-3 xl:text-[12px] 2xl:text-sm rounded-xl text-black hidden xl:flex">
              Connect Wallet
            </button>
          </div>
        </div>

        {menu && (
          <div className=" ">
            <div className=" xl:hidden w-[80%] m-auto space-y-3 py-5 ease">
              {links.map((link) => (
                <div className="">
                  <p className="xl:text-[10px]">{link.link}</p>
                </div>
              ))}
              <button className="bg-[#92D940] hover:bg-[#71a832] hover:cursor-pointer py-2 px-3 rounded-xl text-black ">
                Connect Wallet
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminNav;
