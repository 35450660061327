import React from 'react';

const BibkPredict = () => {
    return (
        <div>
            <div className="my-20 ">
                <div className="_0shine py-12 ">
                    <div className="space-y-3 m-auto w-[80%]">
                        <h1 className="text-[24px] font-semibold 2xl:text-[38px] xl:text-[34px] text-[#92D940] ">BIBK Prediction Pool</h1>
                        <p className="md:text-md text-sm leading-relaxed md:leading-normal">
                            A prediction-based competition for crypto enthusiasts, where participants predict the closing price of a specific crypto asset. The winner of the pool, who predicts the closest to the closing price, will win to 1000% of their tokens in pool. Additionally, all participants receive daily incentives for taking part in the prediction pools, irrespective of whether they win or not. The goal of this  competition is to  add an exciting element to the BinaryBlock community and  engage more users in price speculation activities.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BibkPredict;