import React from 'react';
import AdminNav from '../Components/AdminNav';

const PrivacyPolicy = () => {
    return (
        <div>
            <AdminNav />
            <div className="xl:py-10 py-5 pb-20 m-auto w-[90%] ">
                <h1 className="text-center  2xl:text-3xl xl:text-2xl text-xl  text-[#92D940] my-5 font-bold">  Privacy Policy</h1>
                <div className="space-y-10">
                    <div className="head">
                        <h2 className="">Welcome to the Privacy Policy of BINARYBLOCK. (referred to as GpChange SA, Inc "Company," "we," "us," or "our"). This Policy outlines how we collect, utilize, and share data in association with our website, <span className="text-[#92D940]"> <a href="https://binaryblock.io/" className="" target=''> www.binaryblock.io </a></span> , and all our other offerings, products, and services (collectively referred to as the "Services"). Your Services use is subject to this Policy and our Terms of Use.</h2>
                    </div>
                    <div className="">
                        <h2 className="text-[#92D940] text-xl">Summary:</h2>
                        <ul className="list-disc ml-10 space-y-2 ">
                            <li className="">
                                BinaryBlock(GpChange SA) is an incorporated company based in the Republic of Panama, operating <span className="text-[#92D940]"> <a href="https://binaryblock.io/" className="" target=''> www.binaryblock.io </a></span> , and adheres to the Republic of Panama's laws and regulations.
                            </li>
                            <li className="">
                                We do not collect or retain personal data (e.g., names, addresses, emails, IP addresses) related to your use of the Services or interface.
                            </li>
                            <li className="">
                                We collect non-identifiable data, such as public blockchain data, for product development, not user tracking.
                            </li>
                            <li className="">
                                If you voluntarily sign up to receive emails from us, we will retain your email address solely for sending those emails. You can unsubscribe anytime, and we will not attempt to link it with your wallet address, IP address, or other personal data.
                            </li>
                            <li className="">
                                We actively explore ways to enhance consumer privacy, including opt-out options, transitioning to privacy-centric tools, and implementing proxies to anonymize network traffic.
                            </li>
                            <li className="">
                                Users can employ client-side privacy techniques and tools.
                                Our updated privacy policy will reflect any significant changes to our privacy practices.
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <h1 className="text-[#92D940] text-xl">
                            Data Collection:
                        </h1>
                        <p className=""> At BinaryBlock, we prioritize privacy and transparency. We do not maintain user accounts or collect personal data like names or IP addresses. When interacting with the protocol, we solely collect:</p>
                        <ul className="list-disc ml-10 space-y-2">
                            <li className="">
                                <span className="text-[#92D940]">Publicly available blockchain data:</span>  When you connect your non-custodial blockchain wallet to our Services, we log your publicly available blockchain address for insights into your use of the Services and to screen for any prior illicit activity.
                            </li>
                            <li className="">
                                <span className="text-[#92D940]"> Information from other sources:</span>  We may receive information about your wallet address or transactions through our service providers to fulfill legal obligations and prevent fraudulent or illicit activities.
                            </li>
                            <li className="">
                                <span className="text-[#92D940]"> Survey or usability information:</span>   Participation in our surveys or usability studies may entail providing biographical information directly to us.
                            </li>
                            <li className="">
                                <span className="text-[#92D940]">  Correspondence:</span>  Any communications and information you directly provide via email, customer support, or other support channels will be received and recorded.

                            </li>
                            <li className="">
                                <span className="text-[#92D940]">   Biographical information:</span>         When applying for a job with us, we collect the information provided in our Jobs form.

                            </li>
                            <li className="">
                                Providing information is solely for stated purposes and won't be linked to personal data.
                            </li>
                        </ul>
                    </div>
                    <div className="space-y-2">
                        <h1 className="text-xl text-[#92D940]">How We Use Data:</h1>
                        <p className="">       We use the data you provide according to our Terms of Service and legal requirements. This includes supporting services, ensuring safety and security, legal compliance, and aggregating data for analysis and improvement.</p>

                    </div>
                    <div className="space-y-2">
                        <h1 className="text-xl text-[#92D940]">  How We Share Data:</h1>
                        <p className="">   We may share or disclose collected data with service providers for legal compliance, safety, and security in case of business changes or with your explicit consent. We do not share your information with third parties for marketing purposes.</p>

                    </div>
                    <div className="space-y-2">
                        <h1 className="text-xl text-[#92D940]">  Changes to this Policy:</h1>
                        <p className=""> If any significant changes are made to this Policy, we will notify you through the Services. Your continued use of the Services indicates your consent and requires periodic review of this Policy and other Company terms.</p>

                    </div>
                    <div className="space-y-2">
                        <h1 className="text-xl text-[#92D940]">  Contact Us:</h1>

                        <p className="">
                            For any questions about this Policy or how we handle your information, please get in touch with us at <span className="text-[#92D940]"> <a href="mailto:info@binaryblock.io" className="" target=''> info@binaryblock.io </a></span>. </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;