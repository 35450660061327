import "./index.css";
import Footer from "./Components/Footer";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Team from "./Pages/Team";
import Faqs from "./Pages/Faqs";
import { useEffect } from "react";
import NotFound from "./Components/NotFound";
import Home from "./Pages/Home";
import BibkStaking from "./Pages/BibkStaking";
import GenesisStakingtaking from "./Pages/GenesisStaking";
import PredictionPool from "./Pages/PredictionPool";
import BibkMonthly from "./Pages/BibkMonthly";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
import EthereumPrice from "./Components/EthereumPrice";

function App() {
  const location = useLocation();
  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className="flex min-h-[100vh] flex-col">
        <div className="grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/team" element={<Team />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/app" element={<GenesisStakingtaking />} />
            <Route path="/bibkstaking" element={<BibkStaking />} />
            <Route path="/PredictionPool" element={<PredictionPool />} />
            <Route path="/bibkmonthly" element={<BibkMonthly />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
            <Route path="/terms" element={<Terms/>} />
            <Route path="/pricepool" element={<EthereumPrice/>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
