import React, { useState } from 'react';
import PoolCard from './PoolCard';
import BIBKPool from './Tabs/BIBKPool';
import TenBibkPool from './Tabs/TenBibkPool';
import ElevenBibk from './Tabs/ElevenBibk';

const CurrentPositionPredictionPool = () => {
    const [tab1, setTab1] = useState(true)
    const [tab2, setTab2] = useState(false)
    const [tab3, setTab3] = useState(false)

    const [active, setActive] = useState(true)


    function handleTab1() {
        setTab1(true)
        setTab2(false)
        setTab3(false)
    }
    function handleTab2() {
        setTab2(true)
        setTab1(false)
        setTab3(false)
    }
    function handleTab3() {
        setTab2(false)
        setTab1(false)
        setTab3(true)
    }
    return (
        <div className='_0shine p-10 '>
            <div className="xl:grid space-y-4 xl:space-y-0 grid-cols-3 items-center text-center text-xl xl:text-sm  bg-[#5C5C5C] rounded-xl xl:rounded-full py-3 xl:py-2 mb-8 2xl:mb-4">
                <div className={tab1 ? "cursor-pointer bg-[#92D940] font-medium rounded-full w-fit m-auto px-8 py-1 text-black" : "cursor-pointer"}>
                    <h1 className=" " onClick={handleTab1}>1 BIBK Pool</h1>
                </div>
                <div className={tab2 ? "cursor-pointer bg-[#92D940] font-medium rounded-full w-fit m-auto px-8 py-1 text-black" : "cursor-pointer"}>
                    <h1 className="" onClick={handleTab2}>10 BIBK Pool</h1>
                </div>
                <div className={tab3 ? "cursor-pointer bg-[#92D940] font-medium rounded-full w-fit m-auto px-8 py-1 text-black" : "cursor-pointer"}>
                    <h1 className="" onClick={handleTab3}>11 BIBK Pool</h1>
                </div>
            </div>
            <div className="xl:text-[10px]">
                {tab1 && <BIBKPool />}
                {tab2 && <TenBibkPool />}
                {tab3 && <ElevenBibk />}
            </div>
            <div className="">
                <PoolCard />
            </div>
        </div>
    );
};

export default CurrentPositionPredictionPool;